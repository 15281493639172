import axios from 'axios'

export default async function exportByAuthor(params: {
  period: string
  creator_id: number
}): Promise<Blob> {
  const { data } = await axios.get('/api/v1/article/by-author/export', {
    params,
    responseType: 'blob',
  })

  return data
}
