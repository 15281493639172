import { Section } from '@/types/section'
import { Author } from '@/types/author'
import { Widget } from '@/types/articleWidgets'
import { ImageTransform } from './image'
import { Tag } from '@/types/tag'
import { User } from '@/types/user'
import { Video } from '@/types/widget/video'
import { Issue } from '@/types/issue'
import { Star } from '@/types/star'
import { Brand } from '@/types/brand'
import { Biograhy } from './biograhies'
import { SocialDistribution } from './socialDistribution'
import BrandWidget from './widget/brandWidget'
import { Field } from './action'
import { Expert } from './expert'

export enum ArticleTypeCode {
  GENERAL = 'Обычная статья',
  NEWS = 'Новость',
  PHOTO = 'Фото',
  ARTICLE_BRAND = 'Статья о бренде',
  VIDEO = 'Видео',
  VERSTKA = 'Verstka.io',
  TEST = 'Тест',
  BLOG = 'Блог',
  QUESTION = 'Вопрос',
  LONGREAD = 'Лонгрид',
  GOODS = 'Подборка товаров',
  BRAND = 'Бренд',
  BIOGRAPHY = 'Биография',
}

export enum ArticleStatus {
  PUBLISHED = 'published',
  PLANNED = 'planned',
  NOT_PUBLISHED = 'not_published',
  IN_ARCHIVE = 'in_archive',
  DELETED = 'deleted',
}

export interface ArticleEfficientTitle {
  id: number
  title: string
  views: number
  clicks: number
  ctr: number
}
export interface ArticleForm {
  name: string
  type: number | null
  order_date?: string
  external_link?: string
  detail_image_link?: string
  responsibleUsers?: []
  first_published?: string
  active_from?: string
  creator_id?: number
  section_id?: number
  extra_fields?: {
    singleSocialPostTemplate: boolean
  }
  code?: string
  star?: {
    full_name: string
    cyr_name: string
  }
}
export interface ArticleBlock {
  cid: string | null
  prev_cid: string | null
  model?: string
  item_id?: number | null
  type?: string
  text?: string
  id: number
  tag?: string
  widget?: Widget
  created_at?: string
  updated_at?: string
  after_id?: number | null
  next_id?: number
  prev_id?: number | null
  blockingInfo?: ArticleBlockBlockingInfo
  isInoagent?: boolean
}
export interface ArticleBlockBlockingInfo {
  date: string
  sessionId: string
  field: string
  itemId: number
  model: string
  user: User
}

export enum EfficientTitleStatus {
  NOT_ACTIVE = 0,
  ACTIVE = 1,
  FINISHED = 2,
}

export interface EfficientTitle {
  id: number
  title: string
  article_id: number
}

export interface Article {
  id: number
  type: number
  name: string
  code?: string
  accessHashDraft?: string | null
  accessHashProduction?: string | null
  announce_type: number
  first_published: string | null
  fixed_position: number
  fixed_rubric: number
  fixed_subrubric: number
  draft_id: number
  detail_text: string
  preview_text: string
  expert: Expert | null
  fullStats?: Stats[]
  section_id: number | null
  is_dirty: boolean
  text_coloring: number
  detailImage: ImageTransform | null
  detail_image_id: number | null
  previewImage: ImageTransform | null
  preview_image_id: number | null
  previewImage3: ImageTransform | null
  preview_image3_id: number | null
  sectionImage: ImageTransform | null
  section_image_id: number | null
  socialImage: ImageTransform | null
  social_image_id: number | null
  squareImage: ImageTransform | null
  square_image_id: number | null
  licensed_images: number
  draft?: boolean
  mailing_sent?: number
  production?: {
    id: number
    active: number
    first_published: string | null
    fixed_rubric: number
    pinAtMain: number
    status_id: number
  }
  production_id: number
  active: number
  adult: number
  active_from?: string | null
  active_to?: string | null
  section: Section | null
  biographies: Partial<Star>[] | number[]
  brands: Partial<Brand>[] | number[]
  responsibleUsers: Record<string, User[] | number[]>
  detail_text_blocks: ArticleBlock[]
  status_image: number
  status_ready: number
  status_text: number
  rss_not_export: number
  rss_zen_filter: number
  authors: Partial<Author>[] | number[]
  creator_id: number | null
  creator: {
    id: number
    first_name: string | null
    last_name: string | null
  } | null
  tags: Partial<Tag>[] | number[]
  meta_title: string
  meta_description: string
  meta_keywords: string
  use_verstka: number
  c_use_verstka: number
  efficientTitles: ArticleEfficientTitle[]
  efficientTitleStatus: EfficientTitleStatus
  efficientTitleActiveTo: string | null
  map: {
    name: string
    text: string
    params: {
      id: number
      name: string
      child: {
        id: number
        name: string
      }[]
    }[]
  }
  stats: {
    total: number
    shortDynamics: number
    graphs?: {
      name: string
      data: number[]
    }[]
  }
  brand: {
    article_id: number
    city: string | null
    city_id: number | null
    foundation_year: number | null
    grid: string | null
    id: number
    owner: string | null
    product: string | null
    rus_name: string | null
    state: string | null
    state_id: number | null
    link: string | null
    widgets: BrandWidget[]
  }
  status_id: number
  show_on_main: number
  pinAtMain: number
  pinAtSection: number
  is_editorial: number
  is_negative_words?: number
  advertising: number
  native_advertising: number
  social: SocialDistribution[] | null
  is_promo: number
  show_in_lenta: number
  show_in_scroll: number
  in_yandex_news?: 0 | 1
  is_seo: number
  fixed_text: string
  read_page_filter: number
  disable_infinite_scroll: number
  notShowMainPhoto: number
  republish_from?: string | null
  pushArticle: number
  issue_id: number | null
  issue?: Pick<Issue, 'name' | 'id'> | null
  serviceAttributes: {
    resetEfficientTitles: boolean
  }
  url?: string
  extra_fields: {
    singleSocialPostTemplate: boolean
    videoMaterialIds: (number | Video)[]
    trueLieWidget: number
    disableInitialCap: boolean
    repack: number
    noindex: number
    beingCounted: boolean
    openTypeExternalLinks: number
    contentsEnabled: number
    contentsText: string
    efficientTitleEnabled: number
    isAdvertisingArticle: boolean
    advertisingDomain: string | null
    advertisingName: string | null
    advertisingGetField: string | null
    advertisingAge: number | null
    advertisingOther: number | null
    advertisingLegalInfo: string | null
    articleSource: string | null
    articleSourceUrl: string | null
    disableComments: number
    factWidgetDisabled: number
    disableVideoRecommends: number
    detailImageSource: null | string
    pushNumber?: number
    healthWidget: number
    dzenNativeRssMode: string | null
    socialInfo: SocialNetworkPublishInfo[]
    imageTemplates: boolean
    imageTemplateId: number | null
    seoInitiator: number | null
    sendToMarketing: number | null
    negativeWordsCheckDisabled?: boolean
    negativeWords: { [key: string]: string[] }
    inoagents: Record<string, string[]>
    mailingSent: number
    discussionInvite?: string
    expertId: number
    spText: string
    spLink: string
    spAsNewTab: 0 | 1
  }
  star: Biograhy
}

export interface ArticleForComment {
  active: number | null
  active_from: string
  code: string
  comment_count: number
  comments: Comment[]
  id: number
  name: string
  type: number
  section: Section | null
  author: Author
  creator: {
    id: number
    first_name: string | null
    last_name: string | null
  } | null
}

export interface SocialNetworkPublishInfo {
  netName: string
  activeFrom: string | null
  text: string
  imageId: number | string | null
  enabled?: boolean
}

export type ArticleShort = Pick<Article, 'id' | 'name'>

export interface Stats {
  source: string | number
  value: string | number
}

export interface ArticleItemMetaFields {
  type: Field[]
  text_coloring: Field[]
}

export interface TypeArticle {
  caption: string
  disabled: boolean
  value: number
}

export interface ErrorStopWords {
  name?: string[]
  meta_title?: string[]
  meta_description?: string[]
  preview_text?: string[]
  rich_html_blocks?: { [key: string]: string[] }
  stop_words: string[]
}

export interface ListInoagent {
  list: Record<string, string>
  words: string[]
  showBorder: {
    all: boolean
    blocksId: string[]
  }
}
